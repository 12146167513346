<template>
  <el-card class="box-card">
    <div v-if="active === 0">
      <div class="logintitle">
        <div @click="userchange" :style="{ color: usercolor }" style="cursor: pointer">{{$t("lang.Accountlogin")}}</div>
        <div class="line"></div>
        <div @click="phonechange" :style="{ color: phonecolor }" style="cursor: pointer">{{$t("lang.Mobilenumberlogin")}}</div>
        <img class="erweima" v-if="herderurl" style="cursor: pointer" @click="carpesClisck" src="../../assets/imgs/erweima.png"/>
        <div class="dlbj" v-if="herderurl"><p>{{$t("lang.Codescanninglogin")}}</p></div>
      </div>
      <div class="logincenter">
        <div v-show="usershow" class="usermsg">
          <el-form :model="loginFrom" :rules="rules" ref="ruleForm">
            <el-form-item prop="emailval" class="inputcontent">
              <el-input v-model="loginFrom.emailval" :placeholder="$t('lang.Pleaseinputemailss')"></el-input>
              <img :src="require('../../assets/imgs/zhanghao.png')" />
            </el-form-item>
            <el-form-item prop="paswordval" class="inputcontent">
              <img :src="require('../../assets/imgs/mima.png')" />
              <el-input v-model="loginFrom.paswordval" :placeholder="$t('lang.Pleaseloginpassword')" :type="pwdInputType" :readonly='so' @focus="sd"></el-input>
              <img class="eyes-icon" :src="eyeIcon" @click="handleShowPwd" style="cursor: pointer"/>
            </el-form-item>
          </el-form>
          <div class="slider">
            <SliderLogin @loginver="receiveMsg" @isNull="isNulls" :sliderActive="sliderActive" v-if="isShowSliderLogin"/>
          </div>
        </div>
        <div v-show="phoneshow" class="phonemsg">
          <div class="inputcontentPhone">
            <img class="phoneimg" :src="require('../../assets/imgs/shoujihao.png')"/>
            <input v-model="phoneval" :placeholder="$t('lang.Pleaseinputmobilephonenumber')" />
          </div>
          <div class="inputcontentPhone posmsg">
            <img :src="require('../../assets/imgs/yanzhengma.png')" />
            <input v-model="msgval" :placeholder="$t('lang.VerificationCode')" />
            <div class="sendmsg" @click="sendmsg">
              <p v-show="msgtime" style="cursor: pointer">{{$t("lang.Sendverificationcode")}}</p>
              <p v-show="!msgtime">{{ count }}S</p>
            </div>
          </div>
        </div>
        <div class="message">
          <p>{{$t("lang.Noaccount")}},<router-link to="/userreg">{{$t("lang.Registernow")}}</router-link></p>
          <router-link to="/forgetpas">{{$t("lang.forgetpassword")}}?</router-link>
        </div>
        <div class="loginblock" v-show="usershow">
          <button @click="loginbtn">
            <p style="cursor: pointer">{{$t("lang.Signin")}}</p>
          </button>
        </div>
        <div class="loginblock" v-show="phoneshow">
          <button @click="loginPhone">
            <p style="cursor: pointer">{{$t("lang.Signin")}}</p>
          </button>
        </div>
      </div>
    </div>
    <div v-if="active === 1">
      <div class="logintitle">
        <div class="imgsto" @click="cardesImgs">
          <img src="../../assets/imgs/phones.png" alt="" />
          <span>{{$t("lang.Accountlogin")}}</span>
        </div>
      </div>
      <div class="codeimg">
        <div id="weixin">
          <wxlogin appid="wx90eff9300833a5a0" :scope="'snsapi_login'" :theme="'black'" :redirect_uri="'https://cloud.univiso.tv'"></wxlogin>
        </div>
      </div>
    </div>
  </el-card>
</template>
 
<script>
import SliderLogin from "../../components/slier/SliderLogin";
import * as loing from "@/api/apis.js";
import JSEncrypt from "jsencrypt";
import wxlogin from "vue-wxlogin";
import bus from '@/utils/bus.js';
export default {
  data() {
    var userval = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('lang.Pleaseenternumber')));
      }
    };
		let ficationval= (rule, value, callback) => {
		  if (value == "" || value == undefined || value == null) {
		    callback(new Error(this.$t('lang.Pleaseenternumbers')));
		  } else {
		    callback();
		  }
		};
    return {
			active: 0,
			emailval: "",
			paswordval: "",
			phoneval: "",
			msgval: "",
			activeName: "2",
			usershow: true,
			phoneshow: false,
			receiveDate:false,
			usercolor: "#000",
			phonecolor: "#ccc",
			pwdInputType:'password',
			so:true,
			saval: "",
			sliderActive: false,
			rules: {
				emailval: [
					{
						required: true,
						validator: userval,
						trigger: "blur",
					},
				],
				paswordval: [
					{
						required: true,
						validator: ficationval,
						trigger: "blur",
					},
				],
			},
			msgtime: true,
			count: "",
			loginFrom: {
				emailval: "",
				paswordval: "",
				verCode: "",
			},
			eyeIcon: require("../../assets/imgs/close_eyes.png"),
			isShowSliderLogin: true,
			msg: "",
			text: "向右滑动->",
			outerVisible: false,
			// 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
			accuracy: 5,
			code: "",
			herderurl:true
		};
	},
  components: {
		SliderLogin,
		wxlogin,
  },
	created() {
		//console.log(window.location.hostname)
		if(window.location.hostname=='ind.univiso.tv'){
			this.herderurl=false
		}else{
			this.herderurl=true
		}
	},
  mounted() {
    sessionStorage.setItem("actives", 0);
    // var obj = new WxLogin({
    //   id: "weixin",
    //   appid: "wx3bdb1192c22883f3",
    //   scope: "snsapi_login",
    //   // 扫码成功后 跳转的地址
    //   redirect_uri: "http://domain/weixinlogin"
    // });
    // console.log(obj)
    if (this.$route.query.code) {
      this.code = this.$route.query.code;
      this.loginCode(this.code);
    }
		this.getlang()
  },
  methods: {
		sd(){
		this.so=false  
		},
		async getlang(){
			let tysa='0'
			if(window.location.hostname=='ind.univiso.tv'){
				tysa='1'
			}else{
				tysa='0'
			}
			let res=await loing.getlanguage({type:tysa})
			//console.log(res)
			let sk='zhong'
			if(res.data==0){
				sk='zhong'
				this.$i18n.locale = "cn-CN";
			}
			if(res.data==1){
				sk='En'
				this.$i18n.locale = "en-US";
			}
			bus.$emit("langchange", sk);
			sessionStorage.setItem("language", sk);
		},
    // 微信登录
    loginCode(code) {
      let state =
        "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnsCSsXCFWizEJh0yko1SnWvbv/iAEaKNqAvIzsrG25sbxOiZqTFgg83wLwFQ9afigRTToTgqFuusUoO/r7zWaLbKc56pIgFRloVLEd5URg3jlz+5f6bffBplUwXKC5J6CCiSr5n2Fu5j0zlPOnKe2fizFFVpN+4suVkxxmMPihaty7SLzZmdXiZIeVpyryATh1FMbXnRctUmY4TfU4gXZ3NS2KC69ztvJ5eauDIsKxSPS01trbBP6Mv/prHBSBisBQqKJhgwhrSzZpio3jrxk4E5suYv384JrlCYVaFuB0NSuoBf5Pnylbt83jRHLnzQiQ6CdfnV+vu6y4zl3nL9IwIDAQAB";

      loing.loginCode({ code: code, state: state }).then((response) => {
        console.log(response);
        if (response.msg == "1") {
          //console.log("进入");
          this.$router.push("userreg?data=" + response.data);
        } else {
         let email = response.data.email;
            sessionStorage.setItem("token", JSON.stringify(response.data.token));
            loing.userId({ email: response.data.email }).then((res) => {
              sessionStorage.setItem("user", JSON.stringify(res.data));
              if (res.data.type === 1) {
                this.$router.push("nav/user");
              } else {
                this.$router.push("nav/manage");
              }
            });
        }
      });
    },
     onSuccess() {
      //console.log('验证通过')
      this.msg = 'login success'
      //滑动验证成功之后关闭弹出的窗口
      this.outerVisible = false
      this.$refs.slideblock.reset()
    },
    onFail() {
      //console.log('验证不通过')
      this.msg = ''
    },
    onRefresh() {
      //console.log('点击了刷新小图标')
      this.msg = ''
    },
    onFulfilled() {
      //console.log('刷新成功啦！')
    },
    onAgain() {
      //console.log('检测到非人为操作的哦！')
      this.msg = 'try again'
      // 刷新
      this.$refs.slideblock.reset()
    },
    handleClickslide() {
      this.$refs.slideblock.reset()
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    userchange() {
      this.usershow = true;
      this.phoneshow = false;
      this.usercolor = "#000";
      this.phonecolor = "#ccc";
    },
    phonechange() {
      this.usershow = false;
      this.phoneshow = true;
      this.usercolor = "#ccc";
      this.phonecolor = "#000";
    },
    isNulls(val) {
      if (val) {
        if (this.loginFrom.emailval == "") {
          this.$message.error(this.$t('lang.Pleaseenternumber'));
          this.sliderActive = false;
        } else if (this.loginFrom.paswordval == "") {
          this.$message.error(this.$t('lang.Pleaseenternumbers'));
          this.sliderActive = false;
        } else {
          this.sliderActive = true;
        }
      }
    },
    receiveMsg(data) {
			this.receiveDate=data
      loing.getVerCode({ email: this.loginFrom.emailval }).then((response) => {
        this.loginFrom.verCode = response.data;
      });
    },
    handleShowPwd() {
      this.pwdInputType =
        this.pwdInputType === "password" ? "text" : "password";
      this.eyeIcon =
        this.eyeIcon === require("../../assets/imgs/close_eyes.png")
          ? require("../../assets/imgs/kejian.png")
          : require("../../assets/imgs/close_eyes.png");
    },
    loginbtn() {
			if(this.loginFrom.emailval==''||this.loginFrom.paswordval==''){
				this.$message.error(this.$t('lang.Pleaseenternumbersa'))
				return false
			}
      if (this.receiveDate == true) {
        const jse = new JSEncrypt();
        const publiceKey =
          "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDXYI847fATDgZY6gFvGzaR7UXN9c007DEb6M6DvbSE5F3+apLG+PqujLIa8YzvSGntT3YTpN/RNQxo4I7NWIIbHC5u4zobDW1XNJOun5NUgnLglJxI5LrFIo4VzRA8x3TPh4Eb2fuc4eSO3j5XzL2OY8h+H1Npz4JwOdj7Okoc4QIDAQAB";
        jse.setPublicKey(publiceKey);
        let longinval = {
          email: this.loginFrom.emailval,
          password: jse.encrypt(this.loginFrom.paswordval),
          verCode: this.loginFrom.verCode,
        };
        this.isShowSliderLogin = false;
        this.$nextTick(() => {
          this.isShowSliderLogin = true;
        });
        loing.loginAdd(longinval).then((res) => {
				//console.log(this.receiveDate)
          if (res.data === "-1" && res.msg.indexOf('该账号已禁用')!==-1) {
            this.$message.error(this.$t('lang.Thisadministrator'));
          } else {
            let email = res.email;
            sessionStorage.setItem("token", JSON.stringify(res.token));
            loing.userId({ email: email }).then((response) => {
              sessionStorage.setItem("user", JSON.stringify(response.data));
              if (response.data.type === 1) {
                this.$router.push("nav/user");
              } else {
                this.$router.push("nav/manage");
              }
            });
          }
        });
      } else {
        this.loginDis = false;
				this.$message.error(this.$t('lang.Pleaseslideverify'))
      }
			this.receiveDate=false
    },
    loginPhone() {
      if (!this.phoneval) {
        this.$message.error(this.$t('lang.Pleaseinputmobilephonenumber'));
        return;
      }
      if (!this.msgval) {
        this.$message.error(this.$t('lang.Pleaseentertheverificationcode'));
        return;
      }
      let data = {
        mobile: this.phoneval,
        code: this.msgval,
      };
      this.isShowSliderLogin = false;
      this.$nextTick(() => {
        this.isShowSliderLogin = true;
      });
      loing.phoneLogin(data).then( (res) => {
          if (res.msg.indexOf('登录成功')!==-1) {
            let email = res.data.data.email;
            sessionStorage.setItem("token", JSON.stringify(res.data.data.token));
            loing.userId({ email: email }).then((response) => {
              sessionStorage.setItem("user", JSON.stringify(response.data));
              if (response.data.type === 1) {
                this.$router.push("nav/user");
              } else {
                this.$router.push("nav/manage");
              }
            });
          } else {
            // this.sliderActive = false;
          }
        },
        (error) => {
          console.log(error.response.data.msg);
          if (error.response.data.msg.indexOf('您的账号已锁定') !==-1) {
            loing .loginLockout({ email: this.loginFrom.emailval }) .then((res) => {
                this.$router.push("userlocking");
                console.log(res);
              });
          }
        }
      );
    },
    carpesClisck() {
      this.active = 1;
    },
    cardesImgs() {
      this.active = 0;
    },
    sendmsg() {
      /* 发送验证码 */
      if (this.phoneval && !this.timer) {
        let sendval = {
          phone: this.phoneval,
          code: 2,
        };
        loing.sendPhoneCode(sendval).then((res) => {
          let sk=res.msg
          if(res.msg){
          	let wq=sk.split('-')
          	if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
          		sk=wq[0]
          	}else{
          		sk=wq[1]
          	}
          	this.$message.success(sk);
          	if(wq[0].indexOf('操作过于频繁')!=0){
          		this.getCode();
          	}
          }
        });
      }
    },
    getCode() {
      /* 倒计时 */
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.msgtime = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.msgtime = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    //获取Token
    async getTokens() {
      //token字符串转数组
      let usermsg = JSON.parse(sessionStorage.getItem("user"));
      let users = {
        id: usermsg.id,
        email: usermsg.email,
      };
      let res = await loing.getToken(users);
      sessionStorage.setItem("token", JSON.stringify(res.data));
    },
  },
 
};
</script>
</style>
<style lang="less" scpoed>
.usermsg .el-form .el-form-item {
  width: 100%;
}
#weixin {
  width: 100%;
  display: flex;
  justify-content: center;
}

.usermsg
  .el-form
  .el-form-item
  .el-form-item__content
  .el-input
  .el-input__inner {
  width: 100%;
  color: #333 !important;
}
// .phonemsg {
// }
.inputcontent {
  width: 100%;
}
.box-card {
  /* 卡片 */
  width: 32%;
  padding: 0.7rem 0.76rem 0.4rem 0.8rem;
  box-sizing: border-box;
  margin-right: 12%;
  position: relative;
}
.loginblock {
  button {
    // width: 460px;
    width: 100%;
    height: 0.6rem;
    outline-style: none;
    border: none;
    background: linear-gradient(0deg, #333232, #131715);
    border-radius: 6px;
    P {
      font-size: 0.24rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 0.42rem;
    }
  }
}
/* input输入框 */
.inputcontent {
  width: 100%;
  height: 0.62rem;
  margin-bottom: 0.24rem;
  position: relative;
  input {
    outline-style: none;
    width: 100%;
    height: 0.58rem;
    text-decoration: none;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    text-indent: 0.51rem;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 0.58rem;
  }
  img {
    width: 0.18rem;
    height: 0.2rem;
    position: absolute;
    left: 0.23rem;
    top: 0.2rem;
  }
  .eyes-icon {
    width: 0.26rem;
    height: 0.18rem;
    position: absolute;
    top: 0.2rem;
    left: 90%;
    right: 0.2rem !important;
  }
  .phoneimg {
    width: 0.14rem;
    height: 0.2rem;
    position: absolute;
    left: 0;
    top: 0;
  }
}
.inputcontentPhone {
  width: 100%;
  height: 0.62rem;
  margin-bottom: 0.24rem;
  position: relative;
  display: flex;
  input {
    outline-style: none;
    width: 100%;
    height: 0.58rem;
    text-decoration: none;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    text-indent: 0.51rem;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 0.58rem;
  }
  img {
    width: 0.18rem;
    height: 0.2rem;
    position: absolute;
    left: 0.23rem;
    top: 0.2rem;
  }
  .phoneimg {
    width: 0.14rem;
    height: 0.2rem;
    position: absolute;
    left: 0.23rem;
    top: 0.2rem;
  }
}
.logintitle {
  /* 标题 */
  display: flex;
  justify-content: space-around;
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #424242;
  img.erweima {
    /* 二维码 */
    width: 0.83rem;
    height: 0.84rem;
    position: absolute;
    z-index: 10;
    top: 0.1rem;
    right: 0.12rem;
  }
  div.dlbj {
    /* 扫码登录 */
    padding: 0 0.1rem;
    background: #bbd4bd;
    opacity: 0.9;
    border-radius: 2px;
    //background: url("../../assets/imgs/dlbj.png") no-repeat;
    position: absolute;
    z-index: 10;
    top: 0.12rem;
    right: 1.04rem;
    p {
      font-size: 0.16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #44944c;
      text-align: center;
      line-height: 0.3rem;
    }
  }
}
.logincenter {
  margin-top: 0.48rem;
}
/* 登录 */
.message {
  color: #ccc;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-left: -1px;
  // margin-bottom: 33px;
  margin-top: 0.18rem;
  a {
    list-style: none;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #6eb92b;
  }
}
/* 发送验证码定位 */
.posmsg {
  position: relative;
}
.sendmsg {
  width: 1.16rem;
  height: 0.5rem;
  background: #3e3f3e;
  border-radius: 6px;
  position: absolute;
  top: 5px;
  right: 4px;
  z-index: 10;
  p {
    text-align: center;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.5rem;
  }
}
/* 滑块样式 */
.slider {
  width: 100%;
  height: 0.58rem;
  background: #e6e6e6;
  border-radius: 2px;
}
.linerweime {
  position: absolute;
  bottom: -0.13rem;
  left: 0;
  right: 0;
  margin: auto;
  width: 0.5rem;
  background: #000000;
  height: 2px;
}
.codeimg img {
  width: 100%;
  height: 100%;
}
.imgsto {
  height: 0.3rem;
  padding-right: 5px;
  opacity: 0.9;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  top: 0.12rem;
  right: 0;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #44944c;
  display: flex;
  align-items: center;
  background: #ffffff;
}
.imgsto img {
  margin-right: 0.1rem;
}
</style>